import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import error from "../images/404.png"
import { CustomButton } from "../components/pure/customButton/CustomButton"
import { Link } from "gatsby"

const browser = typeof window !== "undefined" && window

const NotFoundPage = () => {
  return (
    browser && (
      // <Layout>
      <section className="hero page-not-found is-fullheight">
        <SEO title="404 Page not found" />
        <div className="error-oops">
          <p>
            OOPS! <span style={{ color: "#fec600" }}>404</span>
          </p>
          <p>PAGE NOT FOUND</p>
          <u>
            <Link
              to="/"
              style={{
                fontFamily: "Luckiest Guy",
                color: "#fec600",
                // fontSize: "26px",
              }}
            >
              HOME
            </Link>
          </u>
        </div>
      </section>
      // <div className="" style={{ width: "100%" }}>

      //  {/* <img src={error}></img> */}
      // </div>
      // </Layout>
    )
  )
}

export default NotFoundPage
